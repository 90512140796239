import type { PluginListenerHandle } from '@capacitor/core';
import { registerPlugin } from '@capacitor/core';
import type { Version } from '@freelancer/secure-android-storage';

// Used to hydrate the FCM background notification payload to webapp
export interface BackgroundNotificationPlugin {
  // Get a list of background notifications(FCM data message)' payload.
  getBackgroundNotificationData(): Promise<{
    data: string[];
  }>;

  // Remove background notification data files from native storage
  deleteDataFiles(): Promise<void>;
}

export const BACKGROUND_NOTIFICATION_PLUGIN_NAME = 'BackgroundNotification';

// Disabling lint rule here to enable the below plugin to align with the
// conventions of naming Capacitor plugins.
// eslint-disable-next-line @typescript-eslint/naming-convention
export const BackgroundNotification =
  registerPlugin<BackgroundNotificationPlugin>(
    BACKGROUND_NOTIFICATION_PLUGIN_NAME,
  );

export interface CustomNotificationActionPerformed {
  actionId: 'video:accept';
  inputValue?: string;
  notification: CustomNotificationSchema;
}

export interface CustomNotificationSchema {
  title: string;
  body: string;
  id: number;
  extra?: any;
}

// Used to handle the notification build and displayed from native code, e.g. Video Call
export interface CustomNotificationPlugin {
  /**
   * listenerFunc is triggered when the user click on the custom notification
   *
   * @since 0.1.0
   * @param eventName
   * @param listenerFunc
   */
  addListener(
    eventName: 'customNotificationActionPerformed',
    listenerFunc: (
      notificationAction: CustomNotificationActionPerformed,
    ) => void,
  ): Promise<PluginListenerHandle>;

  /**
   * Return the notification payload if the app is opened by clicking on the custom notification.
   * Otherwise, the Promise is rejected
   * @since 0.2.0
   */
  getNotification(): Promise<CustomNotificationActionPerformed>;

  /**
   * @since 0.1.0
   */
  pluginVersion(): Promise<Version>;
}

export const CUSTOM_NOTIFICATION_PLUGIN_NAME = 'CustomNotification';

// Disabling lint rule here to enable the below plugin to align with the
// conventions of naming Capacitor plugins.
// eslint-disable-next-line @typescript-eslint/naming-convention
export const CustomNotification = registerPlugin<CustomNotificationPlugin>(
  CUSTOM_NOTIFICATION_PLUGIN_NAME,
);
