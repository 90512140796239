import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, NgZone, PLATFORM_ID } from '@angular/core';
import { isDefined } from '@freelancer/utils';
import { TrackingConsentStatus } from './interface';
import { TrackingConsent } from './tracking-consent.service';
import { TRACKING_CONFIG } from './tracking.config';
import { TrackingConfig } from './tracking.interface';
import type { CustomTrackingEvent, TrackingEventData } from './tracking.model';

@Injectable({
  providedIn: 'root',
})
export class BingAdsTracking {
  private isEnabled = false;
  private isInitialized = false;

  constructor(
    private ngZone: NgZone,
    private trackingConsent: TrackingConsent,
    @Inject(TRACKING_CONFIG) private config: TrackingConfig,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) {}

  async init(): Promise<void> {
    const trackingConsentStatus =
      await this.trackingConsent.getThirdPartyStatus();

    this.isEnabled =
      isDefined(this.config.bingAdsTagId) &&
      isPlatformBrowser(this.platformId) &&
      trackingConsentStatus === TrackingConsentStatus.AUTHORIZED;

    this.loadTrackingSnippet();

    this.isInitialized = true;
  }

  async trackPageView(): Promise<void> {
    if (!this.isInitialized) {
      await this.init();
    }
    if (!this.isEnabled) {
      return;
    }

    this.ngZone.runOutsideAngular(() => {
      window.uetq = window.uetq || [];
      window.uetq.push('pageLoad');
    });
  }

  async trackCustomEvent(
    payload: CustomTrackingEvent & TrackingEventData,
  ): Promise<void> {
    if (!this.isInitialized) {
      await this.init();
    }

    if (!this.isEnabled) {
      return;
    }

    this.ngZone.runOutsideAngular(() => {
      window.uetq = window.uetq || [];
      window.uetq.push('event', payload.name, {});
    });
  }

  private loadTrackingSnippet(): void {
    if (window.uetq || !this.config.bingAdsTagId || !this.isEnabled) {
      return;
    }

    window.uetq = window.uetq || [];

    const pushPageLoadEvent = (): void => {
      const o: any = {
        ti: this.config.bingAdsTagId,
        enableAutoSpaTracking: true,
      };
      o.q = window.uetq;
      window.uetq = new window.UET(o);
      window.uetq.push('pageLoad');
    };

    const bingAdsScript: any = document.createElement('script');
    bingAdsScript.src = '//bat.bing.com/bat.js';
    bingAdsScript.async = true;

    const onReady = (): void => {
      const state = document.readyState;
      if (state && state === 'complete') {
        pushPageLoadEvent();
        bingAdsScript.onload = null;
        bingAdsScript.onreadystatechange = null;
      }
    };

    bingAdsScript.onload = onReady;
    bingAdsScript.onreadystatechange = onReady;

    const scriptTag = document.getElementsByTagName('script')[0];
    (scriptTag.parentNode as Node).insertBefore(bingAdsScript, scriptTag);
  }
}
