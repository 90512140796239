import type { FinancialReportingRecordResultAjax } from './financial-reporting-record.backend-model';
import {
  UserType,
  WithdrawType,
  type FinancialReportingRecord,
} from './financial-reporting-record.model';

export function transformFinancialReportingRecord(
  financialReportingRecord: FinancialReportingRecordResultAjax,
): FinancialReportingRecord {
  const baseFields = {
    id: financialReportingRecord.userId,
    userId: financialReportingRecord.userId,
    hasVerifiedWithdrawMethod:
      financialReportingRecord.hasVerifiedWithdrawMethod,
    isPhoneVerified: financialReportingRecord.isPhoneVerified,
    isKycVerified: financialReportingRecord.isKycVerified,
  };

  let userFields = {};
  if (financialReportingRecord.userType === UserType.INDIVIDUAL) {
    userFields = {
      userType: UserType.INDIVIDUAL,
      userTypeRecord: financialReportingRecord.userTypeRecord,
    };
  }
  if (financialReportingRecord.userType === UserType.BUSINESS) {
    userFields = {
      userType: UserType.BUSINESS,
      userTypeRecord: financialReportingRecord.userTypeRecord,
    };
  }

  let withdrawFields = {};
  if (financialReportingRecord.withdrawType === WithdrawType.AUSTRALIAN_BANK) {
    withdrawFields = {
      withdrawType: WithdrawType.AUSTRALIAN_BANK,
      withdrawTypeRecord: financialReportingRecord.withdrawTypeRecord,
    };
  }
  if (
    financialReportingRecord.withdrawType === WithdrawType.INTERNATIONAL_BANK
  ) {
    withdrawFields = {
      withdrawType: WithdrawType.INTERNATIONAL_BANK,
      withdrawTypeRecord: financialReportingRecord.withdrawTypeRecord,
    };
  }
  if (financialReportingRecord.withdrawType === WithdrawType.DIGITAL_WALLET) {
    withdrawFields = {
      withdrawType: WithdrawType.DIGITAL_WALLET,
      withdrawTypeRecord: financialReportingRecord.withdrawTypeRecord,
    };
  }
  return {
    ...baseFields,
    ...userFields,
    ...withdrawFields,
  };
}
