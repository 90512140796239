import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, NgZone, PLATFORM_ID } from '@angular/core';
import { isDefined } from '@freelancer/utils';
import { TrackingConsentStatus } from './interface';
import { TrackingConsent } from './tracking-consent.service';
import { TRACKING_CONFIG } from './tracking.config';
import { TrackingConfig } from './tracking.interface';
import type { CustomTrackingEvent, TrackingEventData } from './tracking.model';

// File based on D174724 from T252307
@Injectable({
  providedIn: 'root',
})
export class TaboolaPixelTracking {
  private isEnabled = false;
  private isInitialized = false;

  constructor(
    private ngZone: NgZone,
    private trackingConsent: TrackingConsent,
    @Inject(TRACKING_CONFIG) private config: TrackingConfig,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) {}

  async init(): Promise<void> {
    const trackingConsentStatus =
      await this.trackingConsent.getThirdPartyStatus();
    this.isEnabled =
      isDefined(this.config.taboolaPixelId) &&
      isPlatformBrowser(this.platformId) &&
      trackingConsentStatus === TrackingConsentStatus.AUTHORIZED;

    this.loadTrackingSnippet();
    this.isInitialized = true;
  }

  async trackPageView(): Promise<void> {
    if (!this.isInitialized) {
      await this.init();
    }
    if (!this.isEnabled) {
      return;
    }
    this.ngZone.runOutsideAngular(() => {
      window._tfa.push({
        notify: 'event',
        name: 'page_view',
        id: this.config.taboolaPixelId,
      });
    });
  }

  async trackCustomEvent(
    payload: CustomTrackingEvent & TrackingEventData,
  ): Promise<void> {
    if (!this.isInitialized) {
      await this.init();
    }

    if (!this.isEnabled) {
      return;
    }

    if (payload.taboolaEventName) {
      payload.name = payload.taboolaEventName;
    }

    this.ngZone.runOutsideAngular(() => {
      window._tfa.push({
        notify: 'event',
        name: payload.name,
        id: this.config.taboolaPixelId,
      });
    });
  }

  private loadTrackingSnippet(): void {
    if (window._tfa || !this.config.taboolaPixelId) {
      return;
    }

    window._tfa = window._tfa || [];

    const taboolaScriptElementId = 'tb_tfa_script';
    if (!document.getElementById(taboolaScriptElementId)) {
      const t = document.createElement('script');
      t.async = true;
      t.src = `//cdn.taboola.com/libtrc/unip/${this.config.taboolaPixelId}/tfa.js`;
      t.id = taboolaScriptElementId;

      const firstScriptElement = document.getElementsByTagName('script')[0];
      firstScriptElement.parentNode?.insertBefore(t, firstScriptElement);
    }
  }
}
