import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, NgZone, PLATFORM_ID } from '@angular/core';
import { isDefined } from '@freelancer/utils';
import { TrackingConsentStatus } from './interface';
import { TrackingConsent } from './tracking-consent.service';
import { TRACKING_CONFIG } from './tracking.config';
import { TrackingConfig } from './tracking.interface';
import type { CustomTrackingEvent, TrackingEventData } from './tracking.model';

// File based on D174939 from T251875
@Injectable({
  providedIn: 'root',
})
export class OutbrainPixelTracking {
  private isEnabled = false;
  private isInitialized = false;

  constructor(
    private ngZone: NgZone,
    private trackingConsent: TrackingConsent,
    @Inject(TRACKING_CONFIG) private config: TrackingConfig,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) {}

  async init(): Promise<void> {
    const trackingConsentStatus =
      await this.trackingConsent.getThirdPartyStatus();
    this.isEnabled =
      isDefined(this.config.outbrainPixelId) &&
      isPlatformBrowser(this.platformId) &&
      trackingConsentStatus === TrackingConsentStatus.AUTHORIZED;
    this.loadTrackingSnippet();
    this.isInitialized = true;
  }

  async trackPageView(): Promise<void> {
    if (!this.isInitialized) {
      await this.init();
    }
    if (!this.isEnabled) {
      return;
    }
    this.ngZone.runOutsideAngular(() => {
      window.obApi('track', 'PAGE_VIEW');
    });
  }

  async trackCustomEvent(
    payload: CustomTrackingEvent & TrackingEventData,
  ): Promise<void> {
    if (!this.isInitialized) {
      await this.init();
    }

    if (!this.isEnabled) {
      return;
    }

    this.ngZone.runOutsideAngular(() => {
      window.obApi('track', payload.name);
    });
  }

  // Snippet taken from T251875
  private toArray(object: any): any[] {
    return Object.prototype.toString.call(object) === '[object Array]'
      ? object
      : [object];
  }

  private loadTrackingSnippet(): void {
    if (!this.config.outbrainPixelId) {
      return;
    }

    const OB_ADV_ID = this.config.outbrainPixelId;
    if (window.obApi) {
      window.obApi.marketerId = this.toArray(window.obApi.marketerId).concat(
        this.toArray(OB_ADV_ID),
      );
      return;
    }
    window.obApi = function obApi() {
      if (window.obApi.dispatch) {
        // eslint-disable-next-line prefer-rest-params, prefer-spread
        window.obApi.dispatch.apply(window.obApi, arguments);
      } else {
        // eslint-disable-next-line prefer-rest-params
        window.obApi.queue.push(arguments);
      }
    };
    window.obApi.version = '1.1';
    window.obApi.loaded = true;
    window.obApi.marketerId = OB_ADV_ID;
    window.obApi.queue = [];

    const e = document.createElement('script');
    e.async = true;
    e.src = '//amplify.outbrain.com/cp/obtp.js';
    const s = document.getElementsByTagName('script')[0];
    (s.parentNode as Node).insertBefore(e, s);
  }
}
