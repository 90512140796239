/**
 * Data model for a user's financial reporting record.
 *
 * Used for ATO data collection on entry/bid.
 */

export interface FinancialReportingRecordIndividual {
  readonly userType?: UserType.INDIVIDUAL;
  readonly userTypeRecord?: UserTypeIndividual;
}

export interface FinancialReportingRecordBusiness {
  readonly userType?: UserType.BUSINESS;
  readonly userTypeRecord?: UserTypeBusiness;
}

export interface FinancialReportingRecordAustralianBank {
  readonly withdrawType?: WithdrawType.AUSTRALIAN_BANK;
  readonly withdrawTypeRecord?: AustralianBank;
}

export interface FinancialReportingRecordDigitalWallet {
  readonly withdrawType?: WithdrawType.DIGITAL_WALLET;
  readonly withdrawTypeRecord?: DigitalWallet;
}

export interface FinancialReportingRecordInternationalBank {
  readonly withdrawType?: WithdrawType.INTERNATIONAL_BANK;
  readonly withdrawTypeRecord?: InternationalBank;
}

export interface FinancialReportingRecordBase {
  readonly id: number; // Same as User Id
  readonly userId: number;
  readonly hasVerifiedWithdrawMethod: boolean;
  readonly isPhoneVerified: boolean;
  readonly isKycVerified: boolean;
}

export type FinancialReportingRecord = FinancialReportingRecordBase &
  (FinancialReportingRecordIndividual | FinancialReportingRecordBusiness) &
  (
    | FinancialReportingRecordAustralianBank
    | FinancialReportingRecordDigitalWallet
    | FinancialReportingRecordInternationalBank
  );

export enum UserType {
  BUSINESS = 'business',
  INDIVIDUAL = 'individual',
}

export enum WithdrawType {
  AUSTRALIAN_BANK = 'australian_bank',
  INTERNATIONAL_BANK = 'international_bank',
  DIGITAL_WALLET = 'digital_wallet',
}

export interface AustralianBank {
  readonly accountName: string;
  readonly bsb: string;
  readonly accountNumber: string;
  readonly timeCreated?: number;
}

export interface DigitalWallet {
  readonly paymentServiceProvider: string;
  readonly accountIdentifier: string;
  readonly timeCreated?: number;
}

export interface InternationalBank {
  readonly accountName: string;
  readonly swiftCode: string;
  readonly internationalBankAccountNumber: string;
  readonly timeCreated?: number;
}

export interface UserTypeBusiness {
  readonly businessName?: string;
  readonly taxIdentificationNumber?: string;
  readonly phoneCountryCode?: string;
  readonly phoneNumber?: string;
}

export interface UserTypeIndividual {
  readonly dateOfBirth?: string;
  readonly phoneCountryCode?: string;
  readonly phoneNumber?: string;
}

export type WithdrawTypeRecord =
  | AustralianBank
  | DigitalWallet
  | InternationalBank;

export type UserTypeRecord = UserTypeIndividual | UserTypeBusiness;
