import { Inject, Injectable } from '@angular/core';
import { ActivatedRoute } from '@freelancer/activated-route';
import { Auth } from '@freelancer/auth';
import { arrayIsShallowEqual, Datastore } from '@freelancer/datastore';
import type { UsersCollection } from '@freelancer/datastore/collections';
import { Enterprise } from '@freelancer/datastore/collections';
import { Pwa } from '@freelancer/pwa';
import { UI_CONFIG } from '@freelancer/ui/ui.config';
import { UiConfig } from '@freelancer/ui/ui.interface';
import type { Observable } from 'rxjs';
import { of } from 'rxjs';
import {
  distinctUntilChanged,
  map,
  shareReplay,
  startWith,
  switchMap,
} from 'rxjs/operators';
import type {
  ConfigurableFeatures,
  Feature,
  FeatureFlagConfig,
} from './feature-flags.model';
import {
  BLACKLISTED_INSTALLED_FEATURES,
  BLACKLISTED_IOS_FEATURES,
  featureFlagConfigurations,
  SiteTheme,
} from './feature-flags.model';

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagsService {
  private flagConfig$: Observable<FeatureFlagConfig>;
  private featureFlagBlacklist: Feature[] = [];
  featureFlagBlacklist$: Observable<Feature[]>;
  ready$: Observable<boolean>;

  constructor(
    private datastore: Datastore,
    private auth: Auth,
    private pwa: Pwa,
    private activatedRoute: ActivatedRoute,
    @Inject(UI_CONFIG) private uiConfig: UiConfig,
  ) {
    const { theme } = this.uiConfig;
    if (theme !== undefined && theme in featureFlagConfigurations) {
      this.flagConfig$ = of(featureFlagConfigurations[theme]);
    } else {
      const currentUserDoc = this.datastore.document<UsersCollection>(
        'users',
        this.auth.getUserId(),
      );
      // combine the status and value observables into an `Rx.Observable<User | undefined>`
      const loadedUser$ = currentUserDoc.status$.pipe(
        switchMap(status =>
          status.error ? of(undefined) : currentUserDoc.valueChanges(),
        ),
        distinctUntilChanged(
          (a, b) =>
            !!a && !!b && arrayIsShallowEqual(a.enterpriseIds, b.enterpriseIds),
        ),
      );

      this.flagConfig$ = this.auth.isLoggedIn().pipe(
        switchMap(loggedIn =>
          loggedIn
            ? loadedUser$.pipe(
                map(user => {
                  // Datastore errored, fallback to default theme
                  if (!user) {
                    return featureFlagConfigurations[SiteTheme.DEFAULT];
                  }

                  if (user.isDeloitteDcUser) {
                    return featureFlagConfigurations[SiteTheme.DELOITTE];
                  }

                  if (user.enterpriseIds?.includes(Enterprise.PMI)) {
                    return featureFlagConfigurations[SiteTheme.PMI];
                  }

                  if (user.enterpriseIds?.includes(Enterprise.YARA)) {
                    return featureFlagConfigurations[SiteTheme.YARA];
                  }

                  if (user.enterpriseIds?.includes(Enterprise.GELATO)) {
                    return featureFlagConfigurations[SiteTheme.GELATO];
                  }

                  if (user.enterpriseIds?.includes(Enterprise.HP)) {
                    return featureFlagConfigurations[SiteTheme.HP];
                  }

                  return featureFlagConfigurations[SiteTheme.DEFAULT];
                }),
              )
            : of(featureFlagConfigurations[SiteTheme.DEFAULT]),
        ),
      );
    }

    this.featureFlagBlacklist$ = this.activatedRoute.queryParamMap.pipe(
      map(queryParamMap => {
        const overrides = queryParamMap.getAll(
          'feature_flag_blacklist_overrides[]',
        ) as Feature[];
        // Merge the existing overrides with the new overrides from query param
        this.featureFlagBlacklist = [
          ...new Set(this.featureFlagBlacklist.concat(overrides)),
        ];
        return this.featureFlagBlacklist;
      }),
      distinctUntilChanged(),
    );

    this.ready$ = this.flagConfig$.pipe(
      map(() => true),
      startWith(false),
      distinctUntilChanged(),
      shareReplay({ bufferSize: 1, refCount: true }),
    );
  }

  getFlag(feature: Feature): Observable<boolean> {
    return this.featureFlagBlacklist$.pipe(
      switchMap(() =>
        this.isFeatureDisabled(feature)
          ? of(false)
          : this.flagConfig$.pipe(
              map(config => config[feature]),
              shareReplay({ bufferSize: 1, refCount: true }),
            ),
      ),
    );
  }

  getConfig<T extends keyof ConfigurableFeatures>(
    feature: T,
  ): Observable<ConfigurableFeatures[T]> {
    return this.flagConfig$.pipe(
      map(config => config[feature]),
      shareReplay({ bufferSize: 1, refCount: true }),
    );
  }

  isFeatureDisabled(feature: Feature): boolean {
    return (
      (this.pwa.isNative() &&
        this.pwa.getPlatform() === 'ios' &&
        BLACKLISTED_IOS_FEATURES.includes(feature)) ||
      (this.pwa.isInstalled() &&
        BLACKLISTED_INSTALLED_FEATURES.includes(feature)) ||
      this.featureFlagBlacklist.includes(feature)
    );
  }

  /**
   * Clear the persistent blacklist loaded from query params.
   * Used in UI tests - should not be needed in application code.
   */
  clearBlacklist(): void {
    this.featureFlagBlacklist = [];
    // this.featureFlagBlacklist$ will use this value next time it emits.
  }
}
