import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, NgZone, PLATFORM_ID } from '@angular/core';
import { isDefined } from '@freelancer/utils';
import { TrackingConsentStatus } from './interface';
import { TrackingConsent } from './tracking-consent.service';
import { TRACKING_CONFIG } from './tracking.config';
import { TrackingConfig } from './tracking.interface';
import type { CustomTrackingEvent, TrackingEventData } from './tracking.model';

// File based on D168828 from T236968
/**
 * Listed below are the standard Reddit Pixel Tracking events.
 *
 * More info here: https://business.reddithelp.com/s/article/Install-the-Reddit-Pixel-on-your-website
 */
export enum RedditStandardEvents {
  PAGE_VISIT = 'PageVisit',
  VIEW_CONTENT = 'ViewContent',
  SEARCH = 'Search',
  ADD_TO_CART = 'AddToCart',
  ADD_TO_WISHLIST = 'AddToWishlist',
  PURCHASE = 'Purchase',
  LEAD = 'Lead',
  SIGN_UP = 'SignUp',
  CUSTOM = 'Custom',
}

@Injectable({
  providedIn: 'root',
})
export class RedditPixelTracking {
  private isEnabled = false;
  private isInitialized = false;

  constructor(
    private ngZone: NgZone,
    private trackingConsent: TrackingConsent,
    @Inject(TRACKING_CONFIG) private config: TrackingConfig,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) {}

  async init(): Promise<void> {
    const trackingConsentStatus =
      await this.trackingConsent.getThirdPartyStatus();
    this.isEnabled =
      isDefined(this.config.redditPixelId) &&
      isPlatformBrowser(this.platformId) &&
      trackingConsentStatus === TrackingConsentStatus.AUTHORIZED;
    this.loadTrackingSnippet();
    this.isInitialized = true;
  }

  async trackPageView(): Promise<void> {
    if (!this.isInitialized) {
      await this.init();
    }
    if (!this.isEnabled) {
      return;
    }
    this.ngZone.runOutsideAngular(() => {
      window.rdt('track', RedditStandardEvents.PAGE_VISIT);
    });
  }

  async trackCustomEvent(
    payload: CustomTrackingEvent & TrackingEventData,
  ): Promise<void> {
    if (!this.isInitialized) {
      await this.init();
    }

    if (!this.isEnabled) {
      return;
    }

    this.ngZone.runOutsideAngular(() => {
      window.rdt('track', RedditStandardEvents.CUSTOM, {
        customEventName: payload.name,
      });
    });
  }

  private loadTrackingSnippet(): void {
    if (window.rdt || !this.config.redditPixelId) {
      return;
    }

    window.rdt = function rdt() {
      if (window.rdt.sendEvent) {
        // eslint-disable-next-line prefer-rest-params, prefer-spread
        window.rdt.sendEvent.apply(window.rdt, arguments);
      } else {
        // eslint-disable-next-line prefer-rest-params
        window.rdt.callQueue.push(arguments);
      }
    };

    window.rdt.callQueue = [];
    const t = document.createElement('script');
    t.async = true;
    t.src = 'https://www.redditstatic.com/ads/pixel.js';
    const s = document.getElementsByTagName('script')[0];
    (s.parentNode as Node).insertBefore(t, s);
    window.rdt('init', this.config.redditPixelId);
  }
}
